import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("edit-modal-composer", {
    attrs: {
      "title": "Edit Control",
      "has-last-modified": false,
      "has-deactivate-button": false
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function() {
        return [_c("a-button", {
          on: {
            "click": _vm.cancel
          }
        }, [_vm._v("Cancel")]), _c("a-button", {
          attrs: {
            "type": "primary",
            "loading": _vm.isLoading
          },
          on: {
            "click": _vm.onUpdate
          }
        }, [_vm._v(" Update ")])];
      },
      proxy: true
    }])
  }, [_c("text-input", {
    key: "facility",
    attrs: {
      "disabled": true,
      "span": 12,
      "label": "Facility"
    }
  }), _c("text-input", {
    key: "nextIntake",
    attrs: {
      "disabled": true,
      "span": 12,
      "label": "Next Intake"
    }
  }), _c("text-input", {
    key: "currentSeason",
    attrs: {
      "span": 12,
      "label": "Current Season"
    }
  }), _c("text-input", {
    key: "agAdminEmailName",
    attrs: {
      "span": 12,
      "label": "AgAdmin Email Name"
    }
  }), _c("text-input", {
    key: "isDownloading",
    attrs: {
      "disabled": true,
      "span": 12,
      "label": "Is Downloading"
    }
  }), _c("text-input", {
    key: "downloadCrop",
    attrs: {
      "disabled": true,
      "span": 12,
      "label": "Download Crop"
    }
  }), _c("text-input", {
    key: "nextBinNumber",
    attrs: {
      "span": 12,
      "label": "Next Bin Number"
    }
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "EditSystemAdministration",
  inject: ["crud"],
  data() {
    const apiUrl2 = "#{VUE_APP_API_URL}#";
    return {
      apiUrl: apiUrl2,
      isLoading: false
    };
  },
  methods: {
    cancel() {
      this.$router.push("/watties-grower/system-administration");
    },
    onUpdate() {
      this.isLoading = true;
      try {
        this.crud.submitEntity("replace");
      } finally {
        this.isLoading = false;
      }
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditSystemAdministration = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("resource", {
    attrs: {
      "name": "grower.sys-administrations",
      "api-url": _vm.apiUrl,
      "redirect-route": "/watties-grower/system-administration"
    }
  }, [_c("edit-system-administration")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditSystemAdministration
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
